<template>
  <div>
    <PageTitle v-bind:title="'Evolutions'" />

    <b-container fluid="xxl" class="mt-2">
      <b-row class="mt-4">
        <b-col>
          <LegamDataTables v-bind="$data" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
* >>> .tdAction {
  width: 150px;
}
</style>

<script>
// import router from "@/router";
import PageTitle from "@/components/PageTitle.vue";
import LegamDataTables from "@/components/LegamDataTables.vue";
// import HelpStore from "@/store/helpstore.js";

export default {
  name: "Evolutions",
  data() {
    return {
      columns: [
        {
          title: "Form",
          field: "form",
          sortable: true,
          searchable: true,
        },
        {
          title: "Comment",
          field: "comment",
          sortable: true,
          searchable: true,
        },
        {
          title: "Ordering",
          field: "ordering",
          sortable: true,
          searchable: true,
        },
        {
          title: "Action",
          field: "action",
        },
      ],
      url: "/evolutions/datatables",
      searchableByColumn: true,
    };
  },
  components: {
    PageTitle,
    LegamDataTables,
  },
  methods: {
    // saveHead() {
    //   this.$store.dispatch("createHead", HelpStore.item).then((response) => {
    //     router.push(`/head/${response.data.id}`);
    //   });
    // },
  },
};
</script>
